import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HourMinutesMinPipe, HourMinutesPipe, GdsShortNamePipe, SupplierTypeShortNamePipe } from "./pipes/hour-minutes.pipe";
import { HoursMinutesPipe } from "./pipes/hour-minutes.pipe";
import { RouterModule } from "@angular/router";
import {
  AirportDisplayName,
  AirlineDisplayName,
  AirlineDisplayCode,
  FlightTypeDisplayName,
  DescriptionDisplayName,
  FormatClassPipe,
  FilterByTotalPricePipe,
  HasBaggageIncludedPipe,
  formatClassLongSegmentPipe,
} from "./pipes/generic.pipe";
import { HeaderComponent } from "./header/header.component";
import { HeaderSubComponent } from "./header-sub/header-sub.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { BreadcrumbSubComponent } from "./breadcrumb-sub/breadcrumb-sub.component";
import { SidebarModule } from "primeng/sidebar";
import { TranslateModule } from "@ngx-translate/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CarrouselComponent } from "./carrousel/carrousel.component";
import { TextInputComponent } from './text-input/text-input.component';
import { SharedDropdownComponent } from './shared-dropdown/shared-dropdown.component';
import { DropdownModule } from "primeng/dropdown";
import { FrecuentlyPassengersComponent } from './frecuently-passengers/frecuently-passengers.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FrecuentlyPassengersEditComponent } from "./frecuently-passengers-edit/frecuently-passengers-edit.component";
import { AutoCompleteModule } from "primeng/autocomplete";
import { ChatbotComponent } from './chatbot/chatbot.component';
import { DatatableComponent } from './datatable/datatable.component';
import { DndDirective } from './dnd.directive';
import { FlightTermsAndConditionsComponent } from "./flight-terms-and-conditions/flight-terms-and-conditions.component";
import { PriceDetailsBoxComponent } from './price-details-box/price-details-box.component';
import { PriceDetailsAdminComponent } from './price-details-admin/price-details-admin.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { TimepickerComponent } from './components/timepicker/timepicker.component';
import { RangeHoursMinutesPipe } from "../shared/pipes/hour-minutes.pipe";
import { FlightDetailsComponent } from "./components/flight-details/flight-details.component";
import { TooltipModule } from "primeng/tooltip";

@NgModule({
  declarations: [
    HourMinutesPipe,
    HourMinutesMinPipe,
    GdsShortNamePipe,
    SupplierTypeShortNamePipe,
    DescriptionDisplayName,
    HoursMinutesPipe,
    AirportDisplayName,
    AirlineDisplayName,
    FormatClassPipe,
    formatClassLongSegmentPipe,
    FilterByTotalPricePipe,
    FlightTypeDisplayName,
    AirlineDisplayCode,
    HeaderComponent,
    HeaderSubComponent,
    FooterComponent,
    BreadcrumbComponent,
    BreadcrumbSubComponent,
    FlightTermsAndConditionsComponent,
    CarrouselComponent,
    TextInputComponent,
    SharedDropdownComponent,
    FrecuentlyPassengersComponent,
    FrecuentlyPassengersEditComponent,
    ChatbotComponent,
    DatatableComponent,
    DndDirective,
    PriceDetailsBoxComponent,
    PriceDetailsAdminComponent,
    SpinnerComponent,
    DatepickerComponent,
    TimepickerComponent,
    RangeHoursMinutesPipe,
    HasBaggageIncludedPipe,
    FlightDetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    NgbModule,
    AutoCompleteModule,
    TooltipModule
  ],
  exports: [
    HourMinutesPipe,
    HourMinutesMinPipe,
    GdsShortNamePipe,
    SupplierTypeShortNamePipe,
    HoursMinutesPipe,
    DescriptionDisplayName,
    AirportDisplayName,
    AirlineDisplayName,
    FormatClassPipe,
    formatClassLongSegmentPipe,
    FilterByTotalPricePipe,
    FlightTypeDisplayName,
    AirlineDisplayCode,
    HeaderComponent,
    HeaderSubComponent,
    FooterComponent,
    BreadcrumbComponent,
    BreadcrumbSubComponent,
    FlightTermsAndConditionsComponent,
    CarrouselComponent,
    TextInputComponent,
    SharedDropdownComponent,
    FrecuentlyPassengersComponent,
    FrecuentlyPassengersEditComponent,
    ChatbotComponent,
    DatatableComponent,
    DndDirective,
    PriceDetailsBoxComponent,
    PriceDetailsAdminComponent,
    SpinnerComponent,
    DatepickerComponent,
    TimepickerComponent,
    HasBaggageIncludedPipe,
    FlightDetailsComponent
  ]
})
export class SharedModule {}
